.pmivr-right-panel {
  background-color: $bg-color-white;
  width: 30rem;
  float: right;
  position: fixed;
  height: calc(100vh - 0rem);
  right: -25rem;
  top: 0rem;
  transition-timing-function: linear;
  box-shadow: -4px 0px 5px -5px rgba(0, 0, 0, 0.2);

  .heading {
    font-size: 1rem;
    color: $color-primary;
    font-family: $font-medium;
    white-space: nowrap;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 90%;
    height: $pmivr-input-height;
  }

  .edit-heading-setting {
    min-height: 2.5rem;
  }

  .check-box-label-align {
    width: 100%;
    position: relative;
  }

  .check-box-info-icon {
    position: absolute;
    right: 0;
    top: 2px;
  }

  .invalid-voice-file-info {
    display: flex;
    align-items: center;

    .lang-input {
      width: 10%;
      margin-top: 5px;
    }

    .path-input {
      margin-left: 8px;
      margin-top: 5px;
    }
  }

  .edit-heading-setting button {
    border: dotted 1px $border-color-secondary;
    border-radius: 100%;
    width: 2rem;
    position: relative;
    top: 5px;
    height: 2rem;
  }

  .edit-heading-setting button i {
    font-size: $font-size-primary;
  }

  .edit-heading-setting input {
    max-width: 400px;
    display: block;
    position: relative;
    margin-right: 0.5rem;
    top: 0rem;
  }

  .nav-tabs {
    .nav-item.show {
      .nav-link {
        border: none;
        border-bottom: solid 3px $border-color-secondary;
        width: 50%;
      }
    }

    .nav-link.active {
      border-bottom: solid 3px $border-color-primary;
      color: $color-primary;
    }

    .nav-link {
      border: none;
      width: 50%;
      font-size: 0.75rem;
      color: $color-secondary;
      padding-left: 0.6rem;
      padding-right: 0.6rem;

      &:focus {
        border: none;
        border-bottom: solid 0.1875rem $border-color-primary;
      }

      &:hover {
        border: none;
        border-bottom: solid 0.1875rem $border-color-primary;
      }
    }
  }

  // The context pad still interactable after locking the properties panel. 
  // Fix: increased the z-index for active properties and set z-index: 1 for the ::before, making the context pad also unresponsive.
  &.show.active {
    right: 0px;
    z-index: 2;
  }

  .tab-info-bar {
    clear: both;

    button {
      float: left;
      width: auto !important;
    }
  }

  .close-arrow {
    cursor: pointer;
    color: $color-primary;
    position: absolute;
    right: 0.625rem;
    font-size: 1.25rem;
    top: 0.625rem;
  }

  .accordion-button:not(.collapsed) {
    box-shadow: none;
  }

  .special-condition-btn {
    border: 2px solid transparent !important;

    .plus-icon {
      border: none !important;
      background-color: transparent;
      border-radius: 5px;
    }
  }

  .remove-btn {
    border: none;
    background-color: transparent !important;
    display: flex;
    align-items: center;
    margin-left: auto;

    .minus-icon {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border: 2px solid var(--border-color, $border-color-secondary) !important;
      background-color: transparent !important;
      border-radius: 5px;
      font-size: 22px;
      width: 26px;
      height: 26px;
      padding: 0;

      &:hover {
        background-color: $icon-btn-hover !important;
        border: 2px solid transparent !important;
      }
    }
  }
}

.service-properties {
  .form-check-input {
    display: none;
  }

  .form-check-label {
    padding: 10px 20px;
    cursor: pointer;
    background: var(--tabs-active-bg, #F5F5F5);
    transition: background-color 0.3s ease, color 0.3s ease;
    display: block;
  }

  .form-check-inline:first-child .form-check-label {
    border-left: none;
    margin-left: 10px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .form-check-inline:last-child .form-check-label {
    border-left: none;
    margin-right: 10px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .form-check-inline {
    margin-right: 0px;
    padding-left: 0px;
    flex-grow: 1;
    text-align: center;
  }

  .form-check-input:checked+.form-check-label {
    background-color: $color-primary;
    color: white;
  }
}

.transfer-weekdays {
  height: 46px;
  width: 46px;
  border: none;
  border-radius: 3px;
  background: #e9ecef;
  opacity: 0.7 !important;
  color: $color-primary;
  padding: 0px 16px;
  font-size: 1rem !important;
}

.transfer-holiday-list {
  .transfer-select-date {
    min-height: 2.8rem;
    flex-wrap: wrap;
    display: flex;
    border: 2px solid black;
    font-size: 0.8rem !important;
    width: 100%;
    border-radius: 3px;
    padding: 0 0.5rem;
    border: 2px solid var(--border-color, rgb(184, 195, 228)) !important;
    background: var(--bg-color, rgb(255, 255, 255));

    .pmivr-input {
      padding: 5px 10px;
      border: 1px solid #ced4da;
      border-radius: 4px;
      margin-right: 10px;
      margin-top: 5px;
      display: flex;
      align-items: center;
      background-color: #f8f9fa;
      margin-top: 4px;
      margin-bottom: 4px;
    }

    .remove-date {
      color: $color-red;
      margin-left: 5px;
      cursor: pointer;
      font-size: medium;
    }
  }

  .date-picker {
    height: 23px;
    width: 23px;
    background: transparent;
    color: transparent;
    border: none;
    padding: 0px;
    margin: 0px;
    top: 10px;
    right: 13px;
    z-index: 99999;
  }

  .date-picker-icon {
    right: 14px;
    top: 6px;
    font-size: 22px;
  }

  input {
    border: none;
    box-sizing: border-box;
    outline: 0;
    padding: .75rem;
    position: relative;
    width: 100%;
  }

  input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
  }
}

.position-bottom {
  top: 93%;
}