.pmivr-numbers {
  tbody {
    max-height: calc(100vh - 23rem);
  }
  
  .accordion-button::after {
    padding-right: 30px !important;
  }

  .accordion-button:not(.collapsed)::after {
    margin-right: 10px !important;
  }
}
