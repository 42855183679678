.pmivr-settings {
	.setting-tabs {
		&.scroll {
			max-height: calc(100vh - 11.5rem);
			overflow-y: auto;
			width: 100%;
		}

		.pmivr-text-link {
			color: $color-primary;
			cursor: pointer;
			background: #F5F7FF;
			border: 1px solid transparent;
			width: 300px;
			justify-content: center;
			transition: all 0.5s ease;
		}

		.pmivr-sub-title {
			color: var(--secondary-color, #62778C);
		}

		.pmivr-text-link {
			&:hover {
				background-color: transparent;
				border: 1px dashed blue !important;
				color: blue;
				transition: all 0.5s ease;

				.pmivr-title {
					color: blue;
				}
			}
		}
	}
}