.pmivr-interactive-chat-flow {
    .setting-tabs {
        &.scroll {
            max-height: calc(100vh - 25rem);
            overflow-y: auto;
            width: 100%;
        }
    }

    .active-flow {
        border: 3px solid black !important;
    }

    .no-chat-flow-msg {
        margin-left: 38%;
        font-size: 20px;
        margin-top: 73px;
        font-size: 20px;
    }

    .create-chat-flow-btn {
        margin-left: 38%;
        margin-top: 10px;
        font-size: 12px !important;
        width: 36%;
        height: 29%;
    }

    .chat-flow-description {
        color: rgb(18, 76, 116)
    }

    .back-icon {
        cursor: pointer;
        padding: 0 2px 8px 0;
        font-size: 19px;
    }
}