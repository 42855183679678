.pmivr-user-avatar {

    // For initials of user on the user icon
  .avatar-initials {
    position: absolute;
    bottom: -2px;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 16px;
    color: $color-white;
    font-style: normal;
  }

  // Logout text in the user avatar dropdown
  .logout-text {
    font-size: 12.8px;
  }
  
}
