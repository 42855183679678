.pmivr-table {
	overflow-x: hidden;

	&.table-body-block {
		tbody {
			max-height: calc(100vh - 23rem);
		}
	}

	&.header-fixed {

		thead,
		tbody tr {
			display: table;
			width: 100%;
			line-height: 2rem;
			table-layout: fixed;
			text-align: center;
		}
	}

	thead th {
		background: $table-header-secondary;
		border: none;
		color: $color-black;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		font-size: $font-size-primary
	}

	tbody,
	td,
	tfoot,
	th,
	tr {
		border-style: none !important;
	}

	tbody {
		display: block;
		overflow: auto;
		color: $color-black;
		font-size: 0.9rem;

		tr {
			display: flex;
			overflow: hidden;
			border-bottom: solid 1px $border-color-secondary;

			&:last-child {
				border-bottom: none;
			}

			&:nth-child(even) {
				background: $bg-color-secondary;
			}

			&:nth-child(odd) {
				background: #fff;
			}

			td {
				min-height: 40px;
				vertical-align: middle;
				white-space: nowrap;
				overflow: hidden;
				align-items: center;
				text-overflow: ellipsis;
				font-size: $font-size-primary;
			}
		}
	}
}