.pmivr-app {
  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track:hover {
    background-color: $bg-color-secondary;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background-color: rgba(18, 76, 116, 0.8);
  }
}

.pmivr-scroll::-webkit-scrollbar {
  width: 6px;
}

.pmivr-scroll::-webkit-scrollbar-track:hover {
  background-color: $bg-color-secondary;
}

.pmivr-scroll::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: $color-secondary;
}