.logs-table-summary {
    .pmivr-table {
        &.pmivr-accordian-table {
            tbody {
                max-height: calc(100vh - 23.3rem);
            }

            .accordion-button::after {
                padding-right: 30px !important;
            }

            .accordion-button:not(.collapsed)::after {
                margin-right: 10px !important;
            }

            thead {
                border-bottom: 1px solid #dee2e6;
            }

            .main-table-content button {
                font-family: $primary-font;

                &:not(.collapsed) {
                    box-shadow: none;
                    border: none;
                    color: inherit;
                    background-color: $bg-color-secondary;
                }
            }
        }
    }

    .summary-items {

        i {
            padding: 8px 15px;
        }

        p {
            font-family: $font-medium;
            font-size: 25px !important;
        }
    }
}