.pmivr-leftbar {
	height: 100vh;
	float: left;
	min-height: 100%;
	background-color: $bg-color-primary;
	width: 4.5rem;

	.logo {
		background-color: $bg-color-white;
		border-radius: 0.3rem;
		width: 2.7rem;
		height: 2.6rem;
		margin: .7rem auto;
	}

	ul.leftbar-nav,
	.leftbar-bottom-nav {
		li {
			margin: 1.25rem auto;
			height: $pmivr-input-height;
			width: $pmivr-input-height;
			line-height: $pmivr-input-height;
			color: $color-white;
			font-size: 1.25rem;
			text-align: center;

			a {
				color: $bg-color-white;
				text-decoration: none;
			}

			&:hover,
			&.active {
				cursor: pointer;
				background-color: $color-blue;
				border-radius: 0.3125rem;
			}
		}
	}

	.leftbar-bottom-nav {
		position: fixed;
		margin-left: 1rem;
		bottom: 0;

		li {
			margin: 0rem auto 1.25rem auto;
		}
	}

	li:last-child {
		font-size: 2rem;
	}

	li:last-child:hover {
		background: none;
	}

	li a span {
		font-size: .7rem;
		position: relative;
		top: -20px;
	}

	.tooltip-hover-text {
		color: $color-white;
	}
}