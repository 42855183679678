.password-input-wrapper {
    position: relative;

    i {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        font-size: 1.2em;
    }

    input {
        padding-right: 30px !important;
    }
}