.pmivr-variable {
	overflow: hidden;

	.container-header {
		display: flex;
		align-items: center;
		height: 42px;

		.header-title {
			font-size: 21px;
		}

		.header-actions {
			margin-left: auto;
		}
	}

	.container-content {
		margin-top: 10px;
	}

	.variables-table {
		tbody {
			max-height: calc(100vh - 14.5rem);
		}
	}

	.conditon-heading {
		margin-left: 15px;

	}

	.condition-close-btn {
		margin-right: 15px;
	}

	.close-btn {
		.close-arrow {
			font-size: 1.5rem !important;
			position: relative;
			right: -8px;
			top: -5px;
		}
	}

	.filter-variables {
		position: relative;

		input {
			padding-right: 3rem;
		}

		.btn-search {
			position: absolute;
			cursor: pointer;
			right: 1rem;
			top: 1rem;
		}
	}

	.condition-message {
		text-align: center;
		color: $color-green;
		font-family: initial;
		font-family: $primary-font;
		position: relative;
		height: 0px;
	}
}