// CSS for the template component
.pmivr-breadcrumb-list {
	.arrow-left {
		color: #62778C;
	}
}

.template-drop-audio-file {
	.choose-file {
		height: 7.125rem !important;
		line-height: 1.8rem !important;
	}

	.bi-upload {
		font-size: 21px;
	}
}

.template-textarea {
	.doc-comments {
		border: 2px solid var(--border-color, $border-color-secondary) !important;
		border-radius: 10px;
	}
}

.template-card {
	cursor: pointer;
}

#template-card-active {
	border-radius: 10px;
	border: 1px solid $color-blue !important;
	background: var(--bg-color, $color-white);
	box-shadow: 0px 0px 0px 4px rgba(35, 100, 246, 0.08);
}

.upload-file-container {
	border-left: 1px solid #f1eeee !important;
	height: 100vh;
}

.template-textarea {
	textarea {
		width: 100%;
	}
}

.template-card-disable {
	background-color: #e9ecef !important;
	cursor: default;

	h6 {
		opacity: 0.7;
	}
}