.pmivr-client-flow {

    .active-icon {
        position: absolute;
        top: -30px;
        right: -15px;
        font-size: 65px;
    }

    .active-flow {
        border: 2px solid $color-primary !important;

        .active-icon {
            color: $color-primary !important;
        }
    }

    // no flow published or drafted css
    .no-flow-card {
        height: 142.5px;
        background-color: $bg-color-secondary;
        padding: 15px;
        border-radius: 7px;
        border: none;

        h6 {
            margin-top: 49px;
            text-align: center;
        }
    }

    // no flow message dispaly
    .no-flow-msg {
        margin-left: 38%;
        font-size: 20px;
        margin-top: 73px;
    }

    // create flow btn css on businessCode page
    .create-flow-btn {
        margin-left: 38%;
        margin-top: 10px;
        font-size: 12px !important;
        width: 42%;
        height: 29%;
    }

    .coming-soon {
        position: relative;
        margin-top: -5px;
    }

    .left-bar {
        background: $bg-color-secondary;
        height: calc(100vh - 6.3rem);
        overflow-y: auto;

        .btn-add-new {
            width: 100%;
            padding: 1rem;
            margin-bottom: 1rem;
        }
    }

    .latest-flow-heading {
        color: var(--paymentus-p, $color-primary);
        font-size: 16px;
        font-style: normal;
        font-family: $font-bold;
        line-height: normal;
    }

    .info-version-col {
        padding-left: 0px;
    }

    .info-version {
        background-color: $bg-color-secondary;
        padding: 15px;
        border-radius: 7px;
        border: none;
        height: 100%;
    }

    .card {
        -webkit-box-shadow: 0px 5px 6px -4px rgba(0, 0, 0, 0.07);
        -moz-box-shadow: 0px 5px 6px -4px rgba(0, 0, 0, 0.07);
        box-shadow: 0px 5px 6px -4px rgba(0, 0, 0, 0.07);
        max-height: 100%;

        ul.card-list-items {
            margin: 0px;
            padding: 0px;
            text-align: center;

            li {
                display: inline-block;
                padding: 0px 0.7rem;
                font-size: 1rem;
                font-family: $font-medium;

                &:last-child {
                    border-right: none;
                }
            }
        }

        .card-item {
            height: 61px !important;
            padding-top: 20px !important;

            .card-item-text {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                width: 53%;
                vertical-align: top;
            }
        }

        &.card-efect {
            &:hover {
                box-shadow: none;
                border: solid 2px $bg-color-primary !important;
                cursor: pointer;
                transition: all 250ms linear;
            }
        }

        &.card-efect-blue {
            &:hover {
                box-shadow: none;
                border: solid 2px $color-blue !important;
                cursor: pointer;
                transition: all 250ms linear;
            }
        }

        &.card-efect-orange {
            &:hover {
                box-shadow: none;
                border: solid 2px $color-orange !important;
                cursor: pointer;
                transition: all 250ms linear;
            }
        }

        &.card-efect-green {
            &:hover {
                box-shadow: none;
                border: solid 2px $color-green !important;
                cursor: pointer;
                transition: all 250ms linear;
            }
        }

        &.card-efect-red {
            &:hover {
                box-shadow: none;
                border: solid 2px $color-red !important;
                cursor: pointer;
                transition: all 250ms linear;
            }
        }

        &.flow-type-card {
            border-radius: 10px;
            background: #F5F7FF;
            width: 100%;
            height: 220px;
            flex-shrink: 0;

            &.flow-type-card-red {
                border-left: 2px solid $color-red !important;

                &:hover {
                    cursor: pointer;
                    border: dashed 2px $color-red !important;
                    background: var(--bg-color, $color-white);

                    .icon-container {
                        background-color: $color-red;
                        border: none;

                        i {
                            color: var(--secondry-color, $color-white);
                        }
                    }
                }
            }

            &.flow-type-card-purple {
                border-left: 2px solid $color-purple !important;

                &:hover {
                    cursor: pointer;
                    border: dashed 2px $color-purple !important;
                    background: var(--bg-color, $color-white);

                    .icon-container {
                        background-color: $color-purple;
                        border: none;

                        i {
                            color: var(--secondry-color, $color-white);
                        }
                    }
                }
            }

            &.flow-type-card-blue-grey {
                border-left: 2px solid $color-blue-grey !important;

                &:hover {
                    cursor: pointer;
                    border: dashed 2px $color-blue-grey !important;
                    background: var(--bg-color, $color-white);

                    .icon-container {
                        background-color: $color-blue-grey;
                        border: none;

                        i {
                            color: var(--secondry-color, $color-white);
                        }
                    }
                }
            }

            &.flow-type-card-green {
                border-left: 2px solid $color-green !important;

                &:hover {
                    cursor: pointer;
                    border: dashed 2px $color-green !important;
                    background: var(--bg-color, $color-white);

                    .icon-container {
                        background-color: $color-green;
                        border: none;

                        i {
                            color: var(--secondry-color, $color-white);
                        }
                    }
                }
            }

            &.flow-type-card-orange {
                border-left: 2px solid $color-orange !important;

                &:hover {
                    cursor: pointer;
                    border: dashed 2px $color-orange !important;
                    background: var(--bg-color, $color-white);

                    .icon-container {
                        background-color: $color-orange;
                        border: none;

                        i {
                            color: var(--secondry-color, $color-white);
                        }
                    }
                }
            }

            &.flow-type-card-blue {
                border-left: 2px solid $color-blue !important;

                &:hover {
                    cursor: pointer;
                    border: dashed 2px $color-blue !important;
                    background: var(--bg-color, $color-white);

                    .icon-container {
                        background-color: $color-blue;

                        i {
                            color: var(--secondry-color, $color-white);
                        }
                    }
                }
            }
        }

        .icon-container {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;
            border: 2px dotted #888;
            border-radius: 100%;
            margin: 0 auto;
        }

        .icon-container i {
            color: var(--secondry-color, #62778C);
            text-align: center;
            font-size: 36px;
            font-style: normal;
            font-weight: 300;
            line-height: 28px;
        }
    }

    .business-code-version {
        height: 86vh;
        overflow-y: scroll !important;

        .info-header {
            background-color: $bg-color-secondary;
            padding: 15px 5px;
            border-radius: 7px;
            margin-right: 0px;

            p {
                font-weight: 600;
                font-size: 18px !important;
            }
        }

        .disable-view-dnid {
            cursor: not-allowed;
            opacity: 0.5;
        }

        .version-list {
            .ver-heading {
                color: $color-primary;
                font-family: $font-medium;
                background-color: $bg-color-white;
                font-size: 1rem;
            }

            .list-group {

                a,
                .flag-submenu-btn {
                    text-decoration: none;
                    color: $color-primary;
                }
            }

            ul {
                height: calc(100vh - 28rem);

                li {
                    border: solid 1px $border-color-secondary;
                    border-radius: 0.3rem;
                    margin-bottom: 0.5rem;

                    a {
                        span {
                            color: $color-black;
                            font-weight: 500;
                        }
                    }

                    &:hover {
                        background-color: $bg-color-secondary;
                    }
                }
            }
        }
    }

    .user-initials-circle {
        width: 35px;
        height: 35px;
        border-radius: 50%;
        border: 1px solid rgba(184, 195, 228, 1);
        display: flex;
        justify-content: center;
        align-items: center;
        color: rgba(98, 119, 140, 1);
        font-size: 1rem;
        margin: auto 0px;

        &:hover {
            background-color: $color-blue;
            color: white;
        }

    }

    .text-bold {
        font-weight: 700
    }

    .version-list {
        .refresh-btn {
            padding-left: 0px;
        }

        .pmivr-scroll {
            overflow-x: hidden;
            height: calc(100vh - 30rem);
            overflow-y: auto;
            padding-right: 2px;
        }

        .pmivr-btn-app-text {
            color: var(--secondry-color, #62778C);
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
        }

        .version-btn-align {
            position: relative;

            .flow-failed-btn {
                border: 1px solid var(--btn-color, #f6233c) !important;
            }

            .pmivr-btn-app {
                color: rgba(70, 70, 70, 1) !important;
                background-color: #F9FCFF !important;
                text-align: center;
                border-radius: 6px;
                border: 2px solid var(--btn-color, #2364F6);
                padding: 0.4rem 1rem;
                cursor: pointer;
                font-size: 0.8rem !important;
                box-shadow: -1px 0px 0px 4px rgba(103, 137, 255, 0.09) !important;
                -webkit-box-shadow: 0px 2px 3px -2px rgba(0, 0, 0, 0.24);
                -moz-box-shadow: 0px 2px 3px -2px rgba(0, 0, 0, 0.24);

                &:hover {
                    background-color: #ffffff !important;
                }

                &:focus {
                    box-shadow: none !important;
                    background: none !important;
                }
            }

            .pmivr-btn-error {
                font-size: 0.8rem !important;
            }

            .doc-version-item {
                line-height: 16px;
            }
        }

        .version-btn {
            float: left;
            width: 100%;
        }

        .latest-change-history {
            position: absolute;
            right: 9px;
            margin-top: 30px;
            margin-left: 30px;

            button {
                width: 25px;
                height: 25px;

                &:hover {
                    background-color: #F5F5F5;
                }
            }
        }

        .message-box-scroll {
            overflow-x: hidden;
            height: calc(100vh - 30rem) !important;
            overflow-y: auto;
            padding-right: 2px;
        }

        .latest-comments {
            padding: 0px 5px;
            position: absolute;
            right: 55px;
            margin-top: 6px;
        }

        .comments {
            padding: 0px 5px;
            position: absolute;
            right: 35px;
            margin-top: 0px;
        }

        .working-flag-icon {
            margin-top: -6px;
        }

        .flow-flag {
            width: 22px;
            height: 22px;
            border-radius: 30px;
            border: none;
            color: white;
        }

        .working-flow-flag {
            background-color: green;
        }

        .failed-flow-flag {
            background-color: red;
        }
    }
}

.dnid-display-modal {
    .modal-dialog {
        max-width: 800px !important;
    }
}

.check-box-info-icon {
    margin-left: 61%;
    top: 4px;
    position: relative;
}

.custom-flow-name {
    .error-msg {
        color: red;
        font-size: 12px;
        margin-left: 83px;
    }
}

.flow-in-progress-btn {
    width: 100% !important;
    min-height: 60px;

    .refresh-icon {
        font-size: 20px;
    }
}

.old-version-list {

    .old-version-btn {
        background-color: #ffffff !important;
        text-align: center;
        border: none;
        border-bottom: none;
        padding: 0.4rem 1rem;
        font-size: 0.8rem !important;
        -moz-box-shadow: 0px 2px 3px -2px rgba(0, 0, 0, 0.24);
        transition: background-color 1000ms;

        &:hover {
            background-color: $hover-version-button !important;
            border: none;
            transition: background-color 1000ms;
            -moz-box-shadow: 0px 2px 3px -2px rgba(0, 0, 0, 0.24);
        }
    }

    .working-version-btn {
        background-color: #ffffff !important;
        text-align: center;
        border: none;
        border-bottom: none;
        padding: 0.4rem 1rem;
        font-size: 0.8rem !important;
        -moz-box-shadow: 0px 2px 3px -2px rgba(0, 0, 0, 0.24);
        transition: background-color 1000ms;

        &:hover {
            background-color: $hover-version-button !important;
            border: none;
            transition: background-color 1000ms;
            -moz-box-shadow: 0px 2px 3px -2px rgba(0, 0, 0, 0.24);
        }
    }

    &:hover {
        background-color: $bg-color-secondary;
    }
}

.file-info-text {
    display: inline-block !important;
    width: 110px !important;
}

.dropdown-toggle::after {
    display: none;
}

.sub-menu-toggle {
    padding-left: 4rem;
}

.dropdown-item {
    font-size: 0.85rem;
}

.dropdown-item:active {
    background: white;
}

.upload-flow-version {
    .choose-file {
        height: 4rem !important;
        line-height: 1.4rem !important;
    }

    input {
        height: 4rem !important;
    }

    .pmivr-upload-file {
        .file-path-text {
            min-width: 90px;
            border-right: 2px solid rgb(98, 119, 140) !important;
        }
    }
}