.pmivr-option-user {

	.bind-data-collection {
		background-color: $bg-color-white;
		width: 100%;
		border: solid 1px $border-color-secondary;
		-webkit-box-shadow: 0px 0.1rem 0.2rem -1px rgba(0, 0, 0, 0.1);
		-moz-box-shadow: 0px 0.1rem 0.2rem -1px rgba(0, 0, 0, 0.1);
		box-shadow: 0px 0.1rem 0.2rem -1px rgba(0, 0, 0, 0.1);
	}

	.voice-file-setting-btn {
		margin-left: 5px !important;
		margin-top: 9px !important;
	}
}