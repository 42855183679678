.voice-file-manager {
    .container-fluid {
        background-color: $bg-color-grey;
        height: 100vh;
    }

    .voice-component {
        border-bottom: 1px solid $border-color-light-grey;
        color: $color-black;
        font-family: $primary-font;
        font-size: 15px;
    }

    .voice-component-active {
        background-color: $color-white;
        border-left: 5px solid $color-primary !important;
        color: $color-primary;
    }
}