.full-screen-bg {
	background-color: $bg-color-primary;
}

.pmivr-login {
	background-color: $bg-color-white;

	.row-border {
		border-radius: 11px;
		background: var(--bg-color, #FFF);
		box-sizing: border-box;
		border-left: none;
	}

	.login-left-pannel {
		height: 12.5rem;
		position: relative;
		margin-top: 6rem;
		width: 500px;
		margin-left: auto;
		margin-right: auto;

		.pmivr-title {
			font-weight: 700;
		}

		input.login-input {
			height: 3rem;
			padding: 0px 3rem 0 2.5rem !important;
		}

		.form-group {
			position: relative;

			i {
				position: absolute;
				color: $color-primary;
				font-size: 1.375rem;
				left: 4px;
				top: 1.8rem;
			}
		}

		.rememberme-check {
			padding: 0.3125rem;
		}

		.form-error {
			color: $color-red;
			font-family: $font-medium;
			position: absolute;
			text-align: center;
			width: calc(100% - 7rem);
			top: 2rem;
		}
	}

	.login-right-panel {
		background-color: $bg-color-primary;
		background-image: url(../../../src/static/images/bg-login-img.png);
		background-repeat: no-repeat;
		background-size: cover;
		height: 100vh;

		h2 {
			font-size: 1.975rem;
			line-height: 2.875rem;
			font-family: $font-medium;
			color: $color-white;
		}

		span {
			font-size: 1.5rem;
			color: $color-white;
			display: block;
			font-family: $font-light;
		}

		.login-image {
			margin: 0.9375rem;
			max-width: 100%;
			margin-top: 3rem;
		}
	}
}