.pmivr-search-business-code {
	width: 40rem;
	margin-left: -20rem;
	margin-top: -10.9375rem;
	height: 21.875rem;
	position: absolute;
	top: 50%;
	left: 50%;

	.search-input {
		position: relative;

		input {
			background-color: #ecf1fc;
			border: transparent;
		}

		input:focus button {
			z-index: 9;
		}

		button {
			position: absolute;
			right: 1rem;
			top: 1.8rem;
			z-index: 9;

			i {
				color: $color-secondary;
				font-size: 1rem;
			}

			i:hover {
				color: $color-primary;
			}
		}
	}

	.search-field-error {
		text-align: left;
		color: $color-red;
		font-family: $font-bold;
		padding-top: 0.625rem;
		position: absolute;
	}

	.saved-business-code-card {
		text-align: center;
		cursor: pointer;
		color: $color-secondary;
		display: inline-block;
		border: solid 1px $border-color-secondary;

		&.border-dashed {
			border: 1px dashed $border-color-secondary;
			cursor: default;
			display: flex;
			justify-content: center;
			margin: auto;
			width: 25%;
		}

		&:hover:not(.border-dashed) {
			border-color: $border-color-primary ;
			color: $color-primary;
			-webkit-box-shadow: 0px 3px 5px -4px rgba(16, 79, 121, 1);
			-moz-box-shadow: 0px 3px 5px -4px rgba(16, 79, 121, 1);
			box-shadow: 0px 3px 5px -4px rgba(16, 79, 121, 1);
		}
	}

	.recently-updated-business-code-box {
		min-width: 6.25rem !important;
	}

	.recently-updated-business-code-box-large {
		min-width: 7rem !important;
		border-radius: 0.2rem;
	}
}