$primary-font: 'poppinsregular';
$font-bold: 'poppinsbold';
$font-light: 'poppinslight';
$font-light-italic: 'poppinslight_italic';
$font-medium: 'poppinsmedium';
$font-sami-medium: 'poppinssemibold';

@font-face {
    font-family: $primary-font;
    src: url('../../static/fonts/poppins-regular.woff2') format('woff2'),
         url('../../static/fonts/poppins-regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: $font-bold;
    src: url('../../static/fonts/poppins-bold.woff2') format('woff2'),
         url('../../static/fonts/poppins-bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: $font-light;
    src: url('../../static/fonts/poppins-light.woff2') format('woff2'),
         url('../../static/fonts/poppins-light.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: $font-light-italic;
    src: url('../../static/fonts/poppins-lightitalic.woff2') format('woff2'),
         url('../../static/fonts/poppins-lightitalic.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: $font-medium;
    src: url('../../static/fonts/poppins-medium.woff2') format('woff2'),
         url('../../static/fonts/poppins-medium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: $font-sami-medium;
    src: url('../../static/fonts/poppins-semibold.woff2') format('woff2'),
         url('../../static/fonts/poppins-semibold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}


