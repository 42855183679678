.pmivr-voice-file-list {

  .search-voice-file,
  .filter-voice-file {
    position: relative;

    input {
      padding-right: 3rem;
    }

    .btn-search,
    .btn-filter {
      position: absolute;
      cursor: pointer;
      right: 1rem;
      top: 2.3rem;
    }

    .btn-filter {
      top: 1.9rem;
      font-size: 1.3rem;
    }
  }

  .file-type-text i {
    font-size: 1.2rem;
    position: relative;
    top: 0.2rem;
    padding-left: 0.5rem;
  }

  .voice-file-list {

    tbody {
      max-height: calc(100vh - 17.5rem);
    }

    .icon-font-size {
      font-size: 1.3rem;
    }

    .main-table-content button {
      padding: 8px 0 0 0;

      &:focus {
        box-shadow: none;
        border: none;
      }

      &:not(.collapsed) {
        box-shadow: none;
        border: none;
      }

      .accordion-button::after {
        position: absolute;
        right: 0.8rem;
      }

      td {
        font-size: 0.8rem;
      }
    }
  }

  .accordion-button::after {
    margin-top: -5px;
  }

  .pmivr-fixed-table-header {
    td {
      font-size: 0.8rem;
    }
  }

  .edit-system-voice-files-menu {
    border-left: 1px solid #f1eeee !important;
  }
}

.voice-file-tabs {
  .nav-link {
    color: $color-black;
  }

  .nav-link.active {
    color: $color-primary;
    font-weight: bold;
  }
}

// for showing no voice files message
.tab-content {
  .no-voice-file {
    margin-left: 36%;
    font-size: 27px;
    margin-top: 15%;
  }
}