/*button css code start */
.pmivr-btn-app,
.pmivr-btn-cancel,
.pmivr-btn-secondary,
.pmivr-btn-delete,
.pmivr-btn-outline,
.pmivr-btn-error {
	color: $color-white !important;
	background-color: $bg-color-primary !important;
	text-align: center;
	border-radius: 0.3rem;
	border: solid 1px $border-color-primary;
	padding: 0.4rem 1rem;
	cursor: pointer;
	font-size: 0.9rem !important;
	font-family: $font-medium;
	line-height: normal;
	box-shadow: 0px 2px 3px -2px rgba(0, 0, 0, 0.24);
	-webkit-box-shadow: 0px 2px 3px -2px rgba(0, 0, 0, 0.24);
	-moz-box-shadow: 0px 2px 3px -2px rgba(0, 0, 0, 0.24);
	background-size: 100%;
	background-position: center;
	transition: background 0.8s;

	&:hover {
		background-color: $color-dark-blue !important;
		transition: all 250ms linear;
	}

	&:focus {
		box-shadow: none !important;
		background: $color-dark-blue radial-gradient(circle, transparent 1%, $color-dark-blue 1%) center/15000%;
	}
}

.pmivr-btn-error {
	border: 1px solid red !important;
	background-color: $bg-color-secondary !important;
	color: $color-black !important;
}

.pmivr-btn-transparent {
	background: transparent;
	border: none;
}

.pmivr-btn-outline {
	border: solid 1px $border-color-secondary;
	color: $color-primary;
	background-color: $bg-color-white !important;
}

.pmivr-btn-cancel,
.pmivr-btn-secondary {
	border: solid 0.0625rem $border-color-secondary !important;
	background-color: $bg-color-secondary !important;
	color: $color-black !important;
	font-size: 0.8rem;

	&:hover {
		background-color: $hover-white-button !important;
		transition: all 250ms linear;
	}

	&:focus {
		box-shadow: none !important;
		background: $color-white radial-gradient(circle, transparent 1%, $color-white 1%) center/15000%;
	}
}

.pmivr-btn-app.btn-primary {
	border-color: $border-color-secondary;
}

.pmivr-btn-delete {
	background-color: rgb(180, 47, 62) !important;
	font-size: 0.8rem;
	color: $color-white;
}

.pmivr-add-btn {
	border-radius: 5px;
	border: 2px dashed #DDD !important;
	display: flex;
	flex-shrink: 0;
	cursor: pointer;
	width: 100%;
	padding: 10px;
	align-items: center;
	justify-content: center;

	.plus-icon {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		font-size: 22px;
		width: 26px;
		height: 26px;
		border: 2px solid var(--border-color, $border-color-secondary) !important;
		border-radius: 5px;
		margin-left: 8px;
		background-color: $color-white;
	}

	&:hover {
		background-color: transparent !important;
		border: 2px solid var(--border-color, $border-color-secondary) !important
	}
}

/*button css code end */