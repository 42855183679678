.change-history {
	overflow-y: auto;
	word-break: break-all;

	.history-item {
		border-bottom: 1px solid $border-color-secondary;
		display: flex;
		align-items: center;
		padding: 1rem 0;

		&:first-child {
			padding-top: 0px;
		}
	}
}