.pmivr-modal {

	&.modal-width-medium {
		.modal-dialog {
			max-width: 1024px !important;
		}
	}

	// dialog box with higher z-index(stack order) than loader and all components
	&.pmivr-modal-overlay {
		z-index: 10000;
	}

	.modal-body {
		max-height: 547px;
		overflow-y: auto;
	}

	.modal-header {

		.header-title {
			font-family: "poppinsmedium";
			font-size: 1rem;
			color: $color-primary;
			width: 100%;
		}
	}
}