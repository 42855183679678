$color-primary: rgba(6, 30, 81);
$color-secondary: rgba(98, 119, 140);
$color-black: rgba(51, 51, 51);
$color-white: rgba(255, 255, 255);
$color-blue: rgba(13, 110, 253);
$color-dark-blue: rgba(24, 55, 121, 1);
$color-light-blue: rgb(52, 108, 146);
$color-red: rgba(204, 0, 0);
$color-green: rgba(0, 128, 0);
$color-orange: rgba(255, 165, 0);
$color-purple: rgba(102, 51, 153, 1);
$color-blue-grey: rgb(115, 147, 179);
$bg-color-primary: rgba(6, 30, 81);
$bg-color-secondary: rgba(247, 250, 255);
$bg-color-white: rgba(255, 255, 255);
$bg-color-grey: rgba(245, 246, 250, 1);
$border-color-primary: rgba(18, 76, 116);
$border-color-secondary: rgba(184, 195, 228, 1);
$border-color-light-grey: rgba(221, 221, 221, 1);
$table-header-primary: rgba(18, 76, 116);
$table-header-secondary: rgba(232, 240, 254);
$checkbox-primary: rgba(2, 147, 252, 1);
$hover-white-button: rgba(228, 235, 254, 1);
$icon-btn-hover: rgba(212, 216, 222, 1);
$hover-version-button: rgba(228, 235, 254, 0.3);
$hover-control-btn: rgba(98, 119, 140, 0.07);
$font-size-primary: 0.8rem;
$pmivr-input-height: 2.8rem;