.pmivr-message-page {

    // css for show the close msg of the message page
    .sub-title-msg {
        font-size: 20px;
        color: $color-primary;
    }

    // css for showing title of the message page 
    .title-msg {
        font-size: 30px;
        color: $color-secondary;
    }
}