@import "./common/variables";
@import "./common/fonts";
@import "./common/scroll";
@import "./common/buttons";
@import "./common/form";
@import "./common/table";
@import "./common/modal";

.pmivr-bg-primary {
	background-color: $bg-color-primary !important;
}

.pmivr-bg-secondary {
	background-color: $bg-color-secondary !important;
}

.pmivr-text-primary {
	color: $color-primary !important;
}

.pmivr-text-secondary {
	color: $color-secondary !important;
}

.pmivr-height-100 {
	height: 100% !important;
}

.pmivr-ellipsis {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.pmivr-hide-display {
	display: none !important;
}

.pmivr-inline-display {
	display: inline;
}

.pmivr-block-display {
	display: block;
	height: 100% !important;
}

.pmivr-display-flex {
	display: flex;
}

.pmivr-relative {
	position: relative;
}

.pmivr-clearfix {
	clear: both;
}

.pmivr-text-link {
	color: $color-blue;
	cursor: pointer;
	border: none;
	background-color: transparent;
}

.pmivr-reset-link {
	padding: 0.7rem !important;
}

.pmivr-btn-remove {
	font-size: 1.25rem;
	cursor: pointer;
	position: relative;
	top: 1.3rem;
}

.pmivr-flex-div {
	display: flex;
	display: inline-block !important;
	width: 100%;
}

.pmivr-breadcrumb-list {
	font-family: $font-medium;
	font-size: 0.8rem;

	a {
		color: $color-primary;
		font-weight: bold;

		&:hover {
			color: $color-dark-blue;
		}
	}
}

.pmivr-title {
	font-size: 1rem;
	font-family: "poppinsmedium";
	color: $color-primary;

	&.large {
		font-size: 1.4rem;
	}

	&.medium {
		font-size: 1.2rem;
	}

	&.small {
		font-size: $font-size-primary;
	}
}

.pmivr-sub-title {
	font-size: 1rem;
	font-family: $font-medium;
	color: $color-black;

	&.large {
		font-size: 1.4rem;
	}

	&.medium {
		font-size: 1.2rem;
	}

	&.small {
		font-size: $font-size-primary;
	}
}

.pmivr-card {
	border: solid 1px $border-color-secondary !important;
	background: $bg-color-white;

	&.card-primary {
		background-color: $bg-color-primary;
		border: none !important;
	}

	&.card-secondary {
		background-color: $bg-color-secondary;
		border: none !important;
	}

	&.left-border {
		&.left-border-red {
			border-left: solid 2px $color-red !important;
		}

		&.left-border-green {
			border-left: solid 2px $color-green !important;
		}

		&.left-border-orange {
			border-left: solid 2px $color-orange !important;
		}

		&.left-border-blue {
			border-left: solid 2px $color-blue !important;
		}
	}

	&.border-none {
		border: none !important;
	}

	.card-list-items {
		.text {
			font-weight: normal;
		}
	}
}

.pmivr-container {
	overflow: hidden;
}

.pmivr-left-bar-hidden {
	width: 100%;
}

.pmivr-expand-close {
	background-color: $color-primary;
	padding: 0px;
	color: $color-white;
	border: none;
	margin: auto;
	font-size: 0.7rem;
	height: 2rem;
	width: 2rem;
	border-radius: 3.625rem;
	position: absolute;
	left: -14px;
	top: -20px;

	i {
		font-size: 0.8rem;
	}
}

.pmivr-expand-button {
	padding: 0px;
	color: $color-primary;
	border: none;
	font-size: 0.7rem;
	top: 75px;
	left: 20px;
	position: absolute;
	background: transparent;
	border: none;

	i {
		font-size: 1.3rem;
	}
}

.pmivr-add-option {
	line-height: 1.9rem;
	background-color: transparent;
	border: none;
	cursor: pointer;
	color: $color-primary;

	i {
		width: 1.9rem;
		-webkit-box-shadow: 0px 0.2rem 0.2rem -1px rgba(0, 0, 0, 0.1);
		-moz-box-shadow: 0px 0.2rem 0.2rem -1px rgba(0, 0, 0, 0.1);
		box-shadow: 0px 0.2rem 0.2rem -1px rgba(0, 0, 0, 0.1);
		line-height: 1.9rem;
		float: right;
		font-size: 1.2rem;
		font-style: normal;
		color: $color-white;
		height: 1.9rem;
		background-color: $bg-color-primary;
		border-radius: 100%;

		&.bi-trash3-fill {
			font-size: $font-size-primary !important;
		}
	}
}

.pmivr-loader {
	position: fixed;
	z-index: 9999;
	height: 100%;
	width: 100%;
	overflow: show;
	margin: auto;
	top: 0;
	background-color: rgba(0, 0, 0, 0.5);
	left: 0;
	bottom: 0;
	right: 0;

	.loader-icon {
		border: 0.5rem solid #f3f3f3;
		border-radius: 50%;
		border-top: 0.5rem solid $border-color-primary;
		width: 3.75rem;
		height: 3.75rem;
		-webkit-animation: spin 2s linear infinite;
		animation: spin 2s linear infinite;
		position: absolute;
		left: 50%;
		top: 50%;
		margin-left: -3.75rem;
		margin-top: -3.75rem;
	}

	&.loader-small {
		position: relative;
		background-color: transparent;
		border: 0.5rem solid #f3f3f3;
		border-radius: 50%;
		border-top: 0.5rem solid $border-color-primary;
		width: 2.75rem;
		height: 2.75rem;
		-webkit-animation: spin 2s linear infinite;
		animation: spin 2s linear infinite;
	}
}

.pmivr-tooltip {
	pointer-events: none;
}

.pmivr-label-link {
	cursor: pointer;
	color: $color-blue;
	font-size: 0.625rem !important;
	padding-left: 0.5rem;
	text-decoration: underline;
}

i.pmivr-btn-icon {
	cursor: pointer;
	font-size: 1.125rem;
}

.pmivr-accordion {
	& .accordion-button:not(.collapsed) {
		color: $color-black !important;
		background-color: $bg-color-white !important;
	}

	& .accordion-button:focus {
		box-shadow: none !important;
	}

	& .accordion-voice-item {
		border-radius: 4px;
		border: 0.5px solid #D5D5ED !important;
		background: var(--bg-color, #FFF);
		padding: 4px;

		.accordion-button {
			color: $color-primary !important;
			font-size: $font-size-primary !important;
			padding: 0.4rem 1rem !important;
			border-radius: 12px !important;
			box-shadow: none !important;
			border: none;
		}
	}
}

.pmivr-accordian-tab {
	color: $color-primary !important;
	font-size: 0.9rem !important;
	padding: 0.8rem 1rem !important;
	padding-left: 0px !important;
	border: none;

	&:focus {
		box-shadow: none !important;
		outline: none !important;
	}

	&.accordion-button:not(.collapsed) {
		background-color: $bg-color-white !important;
	}
}

.pmivr-btn-icon {
	border: none !important;
	color: $color-black !important;
	cursor: pointer;
	width: 32px;
	display: flex;
	height: 32px;
	justify-content: center;
	align-items: center;

	&:hover,
	&:active {
		background-color: $icon-btn-hover !important;
		color: $color-black !important;
		border-radius: 100% !important;
	}
}

[disabled],
.pmivr-disabled {
	pointer-events: none !important;
	opacity: 0.7 !important;
	border: none !important;
}

.pmivr-screen-blur {
	height: 100%;
	width: 100%;
	background-color: black;
	opacity: 0.7;
	z-index: 99;
	position: absolute;
	overflow: hidden;
}

.pmivr-rounded-circle-tabs {
	clear: both;
	margin: auto;
	border-radius: 10px;
	background: var(--tabs-active-bg, #F5F5F5);
	padding: 3px;

	.nav-link {
		height: 3rem;
		font-size: 1rem;
		color: $color-primary;
		padding-bottom: 3rem;
	}

	.nav-link.active {
		background-color: $bg-color-white !important;
		color: $bg-color-primary;
		font-family: $font-medium;
	}

	.nav-item.active {
		.nav-link {
			background-color: $bg-color-primary;
			font-family: $font-medium;

			i {
				color: $color-white;
				font-family: $font-medium;
			}
		}
	}
}

.pmivr-btn-rounded-circle {
	width: 2rem;
	height: 2rem;
	padding: 0 !important;

	i {
		font-size: 1rem;
	}

	&.small-rounded-circle {
		width: 1.7rem;
		height: 1.7rem;

		i {
			font-size: 0.8rem;
		}
	}
}

.pmivr-variable-input {
	.rbt-menu {
		-webkit-box-shadow: 0px 6px 9px 1px rgba(0, 0, 0, 0.19);
		-moz-box-shadow: 0px 6px 9px 1px rgba(0, 0, 0, 0.19);
		box-shadow: 0px 6px 9px 1px rgba(0, 0, 0, 0.19);
		border: solid 1px $color-black;
		transform: translate3d(0px, 50.4px, 0px) !important;
	}

	input {
		font-size: $font-size-primary !important;
		width: 100%;
		font-family: $font-medium;
		border-radius: 0.2rem;
		padding: 0 0.5rem;
		border: solid 1px $border-color-secondary;
		height: $pmivr-input-height;
		line-height: 3.125rem;
		border: solid 2px $border-color-secondary !important;

		&.input-lg {
			height: 4.125rem;
			line-height: 4.125rem;
		}

		&.input-sm {
			height: 2.25rem;
			line-height: 1.25rem;
		}

		&:focus {
			box-shadow: 0 0 0 0.1rem rgb(2 83 203 / 35%) !important;
		}

		&:disabled:focus {
			box-shadow: none;
		}
	}

	.dropdown-item {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		font-size: 0.8rem;

		&:hover {
			background-color: #1967d2;
			color: $color-white;
		}
	}
}

.pmivr-dropdown {
	button {
		color: $color-white !important;
		background-color: $bg-color-primary !important;
		text-align: center;
		border-radius: 0.3rem;
		border: none;
		box-shadow: 0px 2px 3px -2px rgba(0, 0, 0, 0.24);

		&:hover {
			opacity: 0.8;
		}

		&:focus {
			box-shadow: none !important;
		}
	}

	.dropdown-toggle {
		padding-left: 10px;
		vertical-align: 0.9em;
		padding-top: 8px;
	}

	.dropdown-menu {
		font-size: 0.8rem;
	}

	.dropdown-item:focus {
		background-color: $bg-color-secondary;
		color: $color-black;

		&:focus {
			color: $color-black;
		}
	}
}

// Styling for the secondary dropdown button to distinguish it visually from the primary one
.pmivr-dropdown-secondary {
	button {
		border: solid 0.0625rem $bg-color-primary !important;
		background-color: $bg-color-secondary !important;
		color: $bg-color-primary !important;
		font-size: medium;

		&:hover {
			background-color: $hover-white-button !important;
			transition: all 250ms linear;
		}
	}

	i {
		color: $bg-color-primary !important;
		font-size: medium;
	}
}

.pmivr-tab-outer {
	margin-top: 80px;
}

.pmivr-filter-headers {
	position: relative;
}

.btn-search-key {
	position: absolute;
	cursor: pointer;
	top: 0.75rem;
	right: 0.7rem;
	font-size: 0.9rem;

	button {
		color: $color-primary;
	}
}

.pmivr-upload-file {

	.success-upload,
	.failed-upload {
		color: $color-green;
		font-size: 0.8rem;
	}

	.file-name,
	.file-size {
		font-size: 1rem;
		white-space: normal;
		border-radius: 5px;
		border: 1px solid $icon-btn-hover;
		background: var(--bg-color, $color-white);
		padding: 15px;
	}

	.file-path {
		border-left: 2px solid $color-secondary;
		padding-left: 20px;
		margin-left: 20px;
	}
}

.pmivr-drop-audio-file {

	.choose-file {
		width: 100%;
		margin: 0.225rem 0 0.625rem 0;
		position: relative;
		background-color: $bg-color-secondary;
		border-radius: 12px;
		border: 0.5px dashed $color-secondary;
		text-align: center;
		padding: 0.75rem 1rem;
		height: 5.125rem;
		line-height: 3.125rem;
		box-shadow: 0px 3px 4px 2px rgba(0, 0, 0, 0.06);
		-webkit-box-shadow: 0px 3px 4px 2px rgba(0, 0, 0, 0.06);
		-moz-box-shadow: 0px 3px 4px 2px rgba(0, 0, 0, 0.06);
		border: 0.5px dashed $checkbox-primary;
		cursor: pointer;

		input {
			opacity: 0;
			height: 5.125rem;
			position: absolute;
			width: 100%;
			top: 0rem;
			left: 0;
		}

		a {
			text-decoration: none;
			color: $color-blue;
		}
	}
}

.pmivr-toast-msg {
	max-width: 400px;
	word-break: break-all;
}

// bootstrap css for toast , we override for our own css
.toast {
	width: auto;

	.toast-header {
		background-color: #312d31;
		color: white;
		font-size: 16px;

		.btn-close {
			background-color: white;
			font-size: 12px;
		}
	}
}

.icon-font-size {
	font-size: 1.3rem;
}

.pmivr-accordian-table {

	.main-table-content button {
		padding: 8px 0 0 0;

		&:focus {
			box-shadow: none;
			border: none;
		}

		&:not(.collapsed) {
			box-shadow: none;
			border: none;
		}

		.accordion-button::after {
			position: absolute;
			right: 0.8rem;
		}

		td {
			font-size: 0.8rem;
		}
	}
}

// disable the arrows of input type number
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	margin: 0;
}

// styling for a large audio player button & spinner
.audio-button-large {
	width: 3rem;
	height: 3rem;
	cursor: pointer;
	text-align: end !important;

	i {
		font-size: 30px;
		color: $color-primary;
	}

	.spinner-border-sm {
		margin-top: 10px;
		--bs-spinner-width: 1.5rem;
		--bs-spinner-height: 1.5rem;
		--bs-spinner-border-width: 0.2em;
	}
}

.cursor-pointer {
	cursor: pointer;
}

.play-button {
	color: $color-primary !important;
}

.popover-message-content {
	min-width: 350px !important;
}