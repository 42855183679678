.pmivr-footer {
    background-color: $bg-color-secondary;
    height: 3rem;
    width: 100%;
    position: fixed;
    bottom: 0;

    &.sidebar-user-avatar {
        width: 5%;
        background-color: transparent;
        align-items: baseline;
        left: 10px;
        z-index: 2;
    }

    .footer-dropdown-menu {
        .dropdown-toggle::after {
            display: none;
        }

        .dropdown-toggle i {
            font-size: 2rem;
            color: $color-blue;
        }

        .dropdown-menu {
            bottom: 1.5rem !important;

            .dropdown-item:hover {
                background-color: $bg-color-secondary;
                color: $color-black;

                &:focus {
                    color: $color-black;
                }
            }
        }
    }
}