html {
	font-size: 1rem;
	padding: 0;
	margin: 0;
}

body {
	font-family: $primary-font !important;
	font-size: 13px !important;
	height: 100%;
	margin: 0;
	overflow: hidden;
	padding: 0;
	border: none;
}

* {
	box-sizing: border-box;
	margin: 0;
	outline: none;
	padding: 0;
}

.pmivr-app {

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-family: $font-medium !important;
	}

	p,
	ul,
	input,
	ol,
	.form-control,
	.btn {
		font-size: $font-size-primary !important;
	}

	h5 {
		font-size: 1.0625rem;
		font-family: "poppinsmedium";
	}
}

@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.hoverEl {
	height: 4.3rem;
	width: 17rem;
	x: 0;
	y: 0;
	cursor: pointer;
}

.hoverEls {
	height: 4.3rem;
	x: 0;
	y: 0;
	cursor: pointer;
}