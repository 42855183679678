.pmivr-right-panel {

	.scroll {
		max-height: calc(100vh - 6rem);
		overflow: hidden;
		overflow-y: auto;
	}

	.collection-info-icon {
		margin-left: 5px;
	}
}