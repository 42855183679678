.pmivr-error-page {
  .invalid-route-error-code {
    font-size: 150px;
    color: $color-primary;
  }

  .invalid-route-error-message {
    font-size: 20px;
    font-weight: 600;
    color: $color-secondary;
  }
}