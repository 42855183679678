.pmivr-label {
	display: flex;
	justify-content: space-between;
	color: $color-primary;
	font-size: $font-size-primary;
}

.pmivr-required-label label:after {
	content: "*";
}

.pmivr-number-input {
	.remove-arrows {

		/* Chrome, Safari, Edge, Opera */
		input::-webkit-outer-spin-button,
		input::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}

		/* Firefox */
		input[type="number"] {
			-moz-appearance: textfield;
		}
	}
}

input.pmivr-input {
	font-size: $font-size-primary !important;
	width: 100%;
	font-family: $font-medium;
	border-radius: 3px;
	padding: 0 0.5rem;
	height: $pmivr-input-height;
	line-height: 3.125rem;
	border: 2px solid var(--border-color, $border-color-secondary) !important;
	background: var(--bg-color, $color-white);

	&.pmivr-error-field {
		border: 1px solid $color-red !important;
	}

	&.input-lg {
		height: 4.125rem;
		line-height: 4.125rem;
	}

	&.input-sm {
		height: 2.25rem;
		line-height: 1.25rem;
	}

	&:focus {
		box-shadow: none;
	}

	&:hover {
		background-color: $bg-color-secondary;
	}

	&:disabled:focus {
		box-shadow: none;
	}
}

textarea.pmivr-input {
	font-size: $font-size-primary !important;
	font-family: $font-medium;
	border: 2px solid var(--border-color, rgb(184, 195, 228)) !important
}

textarea.pmivr-textarea {
	height: 3.125rem;
	border-radius: 0.2rem;
	font-family: $font-medium;
	line-height: 3.125rem;


	&.textarea-lg {
		height: 4.125rem;
		line-height: 4.125rem;
	}

	&.textarea-sm {
		height: 2.25rem;
		line-height: 1.25rem;
	}

	&:focus {
		box-shadow: 0 0 0 0.1rem rgb(2 83 203 / 35%) !important;
	}
}

select.pmivr-select {
	font-size: $font-size-primary !important;
	font-family: $font-medium;
	width: 100%;
	border-radius: 0.2rem;
	padding: 0 0.5rem;
	height: $pmivr-input-height;
	border: 2px solid var(--border-color, $border-color-secondary) !important;
	background-image: url(../../static/images/down-caret.svg);
	background-repeat: no-repeat;
	background-position: right 0.5rem center;
	background-size: 16px 12px;
	appearance: none;

	&.pmivr-error-field {
		border: 1px solid $color-red !important;
	}

	&.select-lg {
		height: 4.125rem;
		line-height: 4.125rem;
	}

	&.select-sm {
		height: 2.25rem;
		line-height: 1.25rem;
	}

	&:focus {
		box-shadow: 0 0 0 0.1rem rgb(2 83 203 / 35%) !important;
	}

	option {
		font-size: $font-size-primary;
		font-family: $primary-font;
	}
}

.pmivr-check-radio {
	.form-check-input {
		height: 1.2rem;
		width: 1.2rem;
		border: solid 1px $checkbox-primary;

		&:checked {
			background-color: $bg-color-primary;
			border-color: $border-color-primary;
		}

		&.center {
			float: none !important;
		}
	}

	.form-check-label {
		font-size: $font-size-primary !important;
		position: relative;
		vertical-align: top;
	}

	label {
		position: relative;
		top: 0.2rem;
		left: 0.4rem;
	}
}

.form-custom-inline {
	display: inline-block !important;
	margin-right: 2.5rem;
	margin-top: 0.5rem;
}

.field-error {
	color: $color-red;
	font-size: $font-size-primary;
}

.field-success {
	color: $color-green;
	font-size: $font-size-primary;
}

.props-custom-input {
	position: relative;
	padding: 1.2rem 0;
	max-width: 100%;

	label {
		color: $color-primary;
		font-weight: 400;
		position: absolute;
		top: 0.1rem;
		left: 0.8rem;
		color: $color-primary;
		font-size: $font-size-primary;
		position: absolute;
		padding: 0 0rem;
		pointer-events: none;
		transform: translateY(30px);
		transition: all 0.2s ease-in-out;
	}

	select {
		font-size: $font-size-primary !important;
		font-family: $font-medium;
		width: 100%;
		border-radius: 0.2rem;
		padding: 0 0.5rem;
		height: $pmivr-input-height;
		border: solid 1px $border-color-secondary;

		option {
			font-size: $font-size-primary;
			font-family: $primary-font;
		}
	}

	input,
	textarea {
		border: none;
		font-family: $font-medium;
		border: 0.0625rem solid $border-color-secondary;
		color: $color-black;
		display: block;
		font-size: $font-size-primary !important;
		padding: 0.375rem 0.75rem;
		width: 100%;
		height: 3.125rem;
		line-height: 2.1875rem;
		border-radius: 0.2rem;

		&:focus {
			outline: none;
			box-shadow: 0 0 0 0.1rem rgb(2 83 203 / 35%) !important;
		}

		&:disabled {
			pointer-events: none;
			outline: none;
			font-weight: 500;
		}

		&:not(:placeholder-shown)+ {
			label {
				color: $color-primary;
				font-size: $font-size-primary;
				left: 0rem;
				top: -0.5rem;
				transform: translateY(0);
				padding: 0px 0rem;
			}
		}

		&.textarea-lg {
			height: 80px;
		}
	}

	input {
		height: $pmivr-input-height;
	}

	input.input-sm {
		height: $pmivr-input-height;
		line-height: 1rem;
	}

	select.select-sm {
		height: $pmivr-input-height;
		line-height: 1rem;
	}

	input.input-sm~label,
	select.select-sm~label {
		top: -0.3rem;
	}

	textarea {
		height: 3.125rem;
		line-height: 2.1875rem;
	}

	select:focus,
	select:valid+ {
		label {
			color: $color-primary;
			font-size: $font-size-primary;
			left: 0rem;
			top: -0.5rem;
			transform: translateY(0);
			padding: 0px 0rem;
		}

		// Hide form label when we select an option
		label.hide-form-label {
			transition: all 0s ease-in-out;
			visibility: hidden !important;
		}
	}
}

.pmivr-select-file-input {
	input[type="file"] {
		display: none;
	}

	.file-info-text {
		padding-top: 5px;
		padding-left: 10px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.custom-file-upload {
		border: 1px solid #ccc;
		display: inline-block;
		padding: 6px 9px;
		cursor: pointer;
	}
}

.pmivr-switch-btn {
	padding: 0px;

	.toggle-btn-height {
		height: 1.5rem !important;
		width: 3rem !important;
	}

	label {
		color: $color-black;
	}

	.form-check-input {
		&:checked {
			background-color: $bg-color-primary;
			border-color: $border-color-primary;
		}
	}
}

.bg-white-box {
	background-color: $bg-color-white;
	border: solid 1px $border-color-secondary;
	-webkit-box-shadow: 0px 0.1rem 0.2rem -1px rgba(0, 0, 0, 0.1);
	-moz-box-shadow: 0px 0.1rem 0.2rem -1px rgba(0, 0, 0, 0.1);
	box-shadow: 0px 0.1rem 0.2rem -1px rgba(0, 0, 0, 0.1);
}

.form-switch {
	input {
		border-radius: 1.875rem !important;
	}
}

.pmivr-form-switch input {
	width: 3rem !important;
}