// on dragging the flow blue color copy of the flow is created which
// causes the flow to gets hanged on screen on diagram page
// we hide that mirror image of the flow but we can drag the flow
.djs-dragging {
	display: none !important;
}

.djs-direct-editing-parent .djs-direct-editing-content {
	font-size: 12px !important;
}

// To hide leftbar panel coming while loading the diagram
.djs-palette {
	visibility: hidden;
}

.bar-zoom {
	display: none;
}

.djs-overlay-context-pad {
	width: 12rem;
	z-index: 9;

	.open {
		background-color: $bg-color-white;
		box-shadow: 0px 0px 2px 2px rgb(0 0 0 / 4%);
		-webkit-box-shadow: 0px 0px 2px 2px rgb(0 0 0 / 4%);
		-moz-box-shadow: 0px 0px 2px 2px rgb(0 0 0 / 4%);
		border-radius: .5rem;
		width: 5rem;
		padding: 0.9rem 0 0.9rem 0;
	}

	.entry {
		cursor: pointer;
		margin: 0 0.2rem 1rem 0.2rem;
		width: 2rem;
		height: 2rem;

		&::before {
			padding: 0.4rem 0 0 0;
		}

		&:hover {
			background-color: $bg-color-primary;
		}

		&:hover:before {
			color: $bg-color-white;
		}
	}
}